import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import CarrieMaxwellHeadshot from '../images/CarrieMaxwell_Headshot.png'
import LoriCannonHeadshot from '../images/LoriCannon_Headshot.png'
import OwenKeehnenHeadshot from '../images/OwenKeehnen_Headshot.png'
import PaulHighfieldHeadshot from '../images/PaulHighfield_Headshot.png'

const honoreeData = [
  {
    name: 'Lori Cannon',
    biography: 'Lori Cannon is one of the last surviving founders of Open Hand – Chicago’s original Meals-on-Wheels program for people with HIV/AIDS; which today, as part of Heartland Alliance, has provided enough food for over 30,000,000 meals. She was a founding member of the Chicago Chapter of the NAMES Project AIDS Memorial Quilt and is a co-founder of the Legacy Project, where she serves on its Board of Trustees. A 1993 Inductee to the Chicago LGBTQ Hall of Fame, Lori is currently engaged in developing “The History of AIDS Memorial Walk” for AIDS Garden Chicago.',
    imageSrc: LoriCannonHeadshot,
  },
  {
    name: 'Paul Highfield',
    biography: 'Paul L. Highfield is a Regional Marketing Manager at Levi Strauss and Company (LS&Co.) – celebrating his 25th year with the company. He has tirelessly volunteered with LS&Co. Community Days, Open Hand Chicago, Groceryland, and as a Legacy Project board member. He is personally responsible for ten (10) of the Legacy Walk™ memorial bronze plaques; and has raised over $200K throughout the past eight (8) years. If you know him, he has more than likely asked you for a donation is some way, shape or form.',
    imageSrc: PaulHighfieldHeadshot,
  },
  {
    name: 'Owen Keehnen',
    biography: 'Author and grassroots historian Owen Keehnen has written several fiction and nonfiction books, most recently Dugan’s Bistro and the Legend of the Bearded Lady. He is a cofounder of the Legacy Project and currently working to make AIDS Garden Chicago a reality. He was inducted into the Chicago LGBT Hall of Fame in 2011.',
    imageSrc: OwenKeehnenHeadshot,
  },
  {
    name: 'Carrie Maxwell',
    biography: "Carrie Maxwell is the Legacy Project's senior biographical researcher and a senior contributing writer for the Windy City Times. Among her other professional endeavors, she was Turner Broadcasting’s Trumpet Awards associate producer/researcher; CNN NewsStand’s library coordinator; and taught various high school social science classes for Chicago Public Schools. In 2002, Carrie received an Emmy Award for contributions to CNN’s breaking news coverage of the September 11, 2001 terrorist attacks on the Twin Towers in New York City; the Pentagon in Washington, D.C.; and in Shanksville, Pennsylvania.",
    imageSrc: CarrieMaxwellHeadshot,
  }
]

const Honorees = () => (
  <Layout>
    <SEO title="Honorees" />
    <p style={{ fontSize: '85%' }}><Link to="/" style={{ color: '#000051', textDecoration: 'none' }}>&#x2190; Back to home</Link></p>
    <h1>Honorees</h1>
    {honoreeData.map((honoree) => (
      <div className="honoree--row" key={honoree.name} >
        <div className="honoree--row-image">
          <img src={honoree.imageSrc} alt={honoree.name} />
        </div>
        <div className="honoree--row-content">
          <h3 style={{ marginBottom: '0.5rem' }}>{honoree.name}</h3>
          <p>{honoree.biography}</p>
        </div>
      </div>
    ))}
  </Layout>
)

export default Honorees
